/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

html,
body {
  font-size: 62.5%;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  text-rendering: optimizeLegibility;
  height: 100%;
  background: rgb(20, 20, 22);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-image-preview-operations-operation:nth-last-of-type(2) {
  display: none;
}

.ant-image-preview-operations-operation:nth-last-of-type(1) {
  display: none;
}

.ant-image-preview-operations {
  position: absolute;
  top: 10px;
  right: 10px;
}

.ant-image-preview-mask {
  background-color: rgb(255, 255, 255);
}

.ant-image-preview-img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
}

.ant-image-mask:hover {
  opacity: 1;
  border-radius: 4px;
}

.ant-image-preview-operations {
  color: rgb(0, 0, 0) !important;
  list-style: none;
  background: transparent;
  pointer-events: auto;
}

.ant-dropdown-menu {
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 6%), 0 6px 16px 0 rgb(0 0 0 / 4%), 0 9px 28px 8px rgb(0 0 0 / 2%);
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: white;
  color: #000000d9;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #000000d9;
}

.react-multiple-carousel__arrow {
  background: none !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 4px);
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 4px);
}

.ant-tooltip-inner {
  background: #424247;
  color: #d1d1d2;
  border-radius: 8px;

  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  padding: 9px;
}

.ant-tooltip-arrow-content {
  background: #424247;
  border-radius: 2px;
}

.ant-modal {
  padding: 0 0 0 0;
}

.ant-modal-body {
  border-radius: 8px;
  padding: 40px;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-select-dropdown {
  margin-top: -4px;
  padding: 4px 0;
  border-radius: 8px;
  background: '#ffffff';
  border: 1px solid #dedede;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: transparent;
}

.ant-select-item-option-content {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.ant-select-item-option-content {
  color: #262626;
}

.ant-select-item {
  padding-left: 10px;
  color: #262626;
}

.ant-select-item-option-active {
  background: transparent !important;
}

.ant-select-item-option-active > div {
  color: #226aed !important;
}

.ant-select-item > div > span {
  margin-right: 8px !important;
}

.ant-select-selector::after {
  color: #226aed !important;
}

.ant-select-arrow .anticon {
  color: #262626;
  opacity: 0.3 !important;
}

.ant-select-item-option-content {
  padding: 2px 8px !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #424247;
}
